<template>
	<el-scrollbar class="uu-box uu-decorate">
		<el-tabs v-model="activeName" class="uu-tabs" @tab-click="handleClick">
			<el-tab-pane :label="$t('seller.decorate.tabPane2')" name="category">
				<decorateCategory></decorateCategory>
			</el-tab-pane>
		</el-tabs>
	</el-scrollbar>
</template>

<script>
import decorateCategory from '@/views/seller/decorate/category.vue';
export default {
	components: { decorateCategory },
	data() {
		return {
			activeName: 'category'
		}
	},
	methods: {
		handleClick(tab, event) {
			if (tab.name == 'category') {
				this.$router.push({ name: 'decorateCategory' })
			}
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs {
	&.uu-tabs {
		.el-tabs__header {
			margin-bottom: 0;

			.el-tabs__nav-wrap {
				.el-tabs__nav {
					margin-left: 20px;
					.el-tabs__item {
						height: 54px;
						line-height: 54px;
					}
				}

				&:after {
					height: 1px;
				}
			}
		}
		.el-tabs__content {
			height: calc(100vh - 129px);

			.el-tab-pane {
				height: 100%;
				box-sizing: border-box;
				padding: 15px;
			}
		}
	}
}
</style>
